import { Colours } from '@/Components/Colours'
import { cn } from '@/Lib'
import { HTMLAttributes, forwardRef, useMemo } from 'react'

const Alert = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    colour?: Colours
  }
>(({ colour, className, ...props }, ref) => {
  const colourVariants = useMemo(() => {
    switch (colour) {
      case Colours.red:
        return 'bg-red-50'
      case Colours.sky:
        return 'bg-sky-50'
      case Colours.green:
        return 'bg-green-50'
      case Colours.orange:
        return 'bg-orange-50'
      case Colours.blue:
        return 'bg-blue-50'
      case Colours.indigo:
        return 'bg-indigo-50'
      case Colours.purple:
        return 'bg-purple-50'
      case Colours.yellow:
        return 'bg-yellow-50'
      case Colours.lime:
        return 'bg-lime-50'
      case Colours.rose:
        return 'bg-rose-50'
      case Colours.slate:
        return 'bg-slate-50'
      default:
        return 'bg-slate-100'
    }
  }, [colour])
  return (
    <div
      ref={ref}
      className={cn(
        'flex w-full gap-x-2 rounded-xl p-4',
        colourVariants,
        className
      )}
      {...props}
    />
  )
})
Alert.displayName = 'Alert'

const AlertTitle = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  return (
    <h3
      ref={ref}
      className={cn('font-medium text-medium text-strong', className)}
      {...props}
    />
  )
})
AlertTitle.displayName = 'AlertTitle'

const AlertBody = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  return <p ref={ref} className={cn('text-medium', className)} {...props} />
})
AlertBody.displayName = 'AlertBody'

const AlertContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex w-full flex-col gap-y-2', className)}
      {...props}
    />
  )
)
AlertContent.displayName = 'AlertContent'

export { Alert, AlertBody, AlertContent, AlertTitle }

import { Icon } from '@/Components/Icon'
import { cn } from '@/Lib'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

const DropdownMenu = DropdownMenuPrimitive.Root

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

const DropdownMenuContent = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(
  (
    {
      className,
      side = 'bottom',
      sideOffset = 0,
      collisionPadding = 16,
      children,
      ...props
    },
    ref
  ) => (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        ref={ref}
        side={side}
        style={{ outline: 'solid', outlineColor: 'rgb(var(--border) / 0.3)' }}
        sideOffset={sideOffset}
        collisionPadding={collisionPadding}
        className={cn(
          'relative flex w-full max-w-[calc(100vw-32px)] gap-2 rounded-2xl bg-white p-1 shadow-lg sm:max-w-[440px]',
          className
        )}
        {...props}
      >
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  )
)
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const DropdownMenuSeparator = DropdownMenuPrimitive.Separator

const DropdownMenuRadioGroup = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.DropdownMenuRadioGroup>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.DropdownMenuRadioGroup>
>(({ className, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.RadioGroup
      className={cn('flex flex-col gap-0', className)}
      {...props}
      ref={ref}
    />
  )
})
DropdownMenuRadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const DropdownMenuRadioGroupItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.RadioItem
      ref={ref}
      className={cn(
        'my-0.5 flex w-full min-w-40 select-none gap-2 truncate rounded-xl p-2 text-start transition-colors first-of-type:mt-0 last-of-type:mb-0 hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus disabled:cursor-not-allowed disabled:text-disabled',
        className
      )}
      {...props}
    >
      {children}
      <DropdownMenuPrimitive.ItemIndicator asChild>
        <Icon className='ml-auto'>check_small</Icon>
      </DropdownMenuPrimitive.ItemIndicator>
    </DropdownMenuPrimitive.RadioItem>
  )
})
DropdownMenuRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioGroupItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
}

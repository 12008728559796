import { Colours } from '@/Components/Colours'
import { cn } from '@/Lib'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { useAnimate } from 'framer-motion'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useEffect,
  useMemo
} from 'react'

const Progress = forwardRef<
  ElementRef<typeof ProgressPrimitive.Root>,
  ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, max, ...props }, ref) => {
  const [scope, animate] = useAnimate()

  const percentage = useMemo(() => {
    return ((value || 0) / (max ?? 100)) * 100
  }, [value, max])

  useEffect(() => {
    animate(
      '#progress-bar',
      { width: `${percentage || 0}%` },
      { duration: 0.3 }
    )
  }, [animate, percentage])

  return (
    <ProgressPrimitive.Root
      ref={scope || ref}
      className={cn('flex h-5 w-full rounded-full bg-slate-200 p-1', className)}
      {...props}
    />
  )
})
Progress.displayName = ProgressPrimitive.Root.displayName

const ProgressIndicator = forwardRef<
  ElementRef<typeof ProgressPrimitive.Indicator>,
  ComponentPropsWithoutRef<typeof ProgressPrimitive.Indicator> & {
    colour?: Colours
  }
>(({ className, colour, ...props }, ref) => {
  const colourVariants = useMemo(() => {
    switch (colour) {
      case Colours.red:
        return 'bg-red-400'
      case Colours.sky:
        return 'bg-sky-400'
      case Colours.green:
        return 'bg-green-400'
      case Colours.orange:
        return 'bg-orange-400'
      case Colours.blue:
        return 'bg-blue-400'
      case Colours.indigo:
        return 'bg-indigo-400'
      case Colours.purple:
        return 'bg-purple-400'
      case Colours.yellow:
        return 'bg-yellow-400'
      case Colours.lime:
        return 'bg-lime-400'
      case Colours.rose:
        return 'bg-rose-400'
      case Colours.slate:
        return 'bg-slate-400'
      default:
        return 'bg-nav'
    }
  }, [colour])

  return (
    <ProgressPrimitive.Indicator
      ref={ref}
      id='progress-bar'
      className={cn('h-full rounded-full', colourVariants, className)}
      style={{ width: `0%` }}
      {...props}
    />
  )
})
ProgressIndicator.displayName = ProgressPrimitive.Indicator.displayName

export { Progress, ProgressIndicator }

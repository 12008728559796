export enum Colours {
  red = 0,
  sky = 1,
  green = 2,
  orange = 3,
  blue = 4,
  indigo = 5,
  purple = 6,
  yellow = 7,
  lime = 8,
  rose = 9,
  slate = 10
}

export function colourFromString(val: string): Colours {
  switch (val) {
    case '0':
      return Colours.red
    case '1':
      return Colours.sky
    case '2':
      return Colours.green
    case '3':
      return Colours.orange
    case '4':
      return Colours.blue
    case '5':
      return Colours.indigo
    case '6':
      return Colours.purple
    case '7':
      return Colours.yellow
    case '8':
      return Colours.lime
    case '9':
      return Colours.rose
    case '10':
    default:
      return Colours.slate
  }
}
